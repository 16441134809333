/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import StorageService from "../helpers/StorageService"

function SEO({ description, lang, meta, title, author, url, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitter
            siteUrl
             image
          }
        }
      }
    `
  )

  const config = StorageService.getService().getConfig()

  if (config.running_env !== "production") {
    return null;
  }

  const metaDescription = description || site.siteMetadata.description
  const metaAuthor = author || site.siteMetadata.author
  let metaUrl = site.siteMetadata.siteUrl
  const metaImage = image || site.siteMetadata.image
  const metaTitle = title || site.siteMetadata.title
  const metaKeywords = meta || ["iOS App Development","SwiftUI", "UIKit", "Apple", "Mobile Development", "Courses", "iOS Contracting", "Swift"]

  if (url) {
    metaUrl = site.siteMetadata.siteUrl + url
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | Liquidcoder Ltd.`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: `Liquidcoder`
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        metaKeywords && metaKeywords.length > 0 ? {
          name: `keywords`,
          content: metaKeywords.join(`, `)
        } : []
      )}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
  author: ``,
  url: ``,
  image: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  author: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string
}

export default SEO
