import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import tw from "tailwind.macro"
import { H4,  TagText } from "../elements/shared/texts"
import { PrimaryLink } from "../elements/shared/buttons"
import { UseGlobalStateContext } from "../context/globalContext"
import PreviewGallery from "./PreviewGallery"
import Video from "./Video"
import {PricingPlans} from "../utils/constants.js"
import { MotionLinkWrapper } from "../elements/shared/buttons"

const CourseCard = styled.div`
  ${tw`rounded-lg mt-4`};
  background-color: ${props => props.theme.colors.deepBackground};
  border-radius: 20px;
  border-color: ${props => props.theme.colors.border};
  box-shadow: 0 30px 60px ${props => props.theme.colors.mainShadow};
  border-width: 1px;
  overflow: hidden;

`

const ImageContainer = styled.div`
  ${tw`sm:p-4 p-0`};
      overflow: hidden;

  
  img{
    border-radius: 20px;
  }

`

const VideoContainer = styled.div`
    ${tw`sm:p-4 p-0`};
    overflow: hidden;
`

export const LoginButton = styled(motion.button)`
  ${tw`block  py-2 px-5 text-white font-medium text-sm sm:text-sm lg:text-md`};
  background-image: linear-gradient(
    -90deg,
    rgb(21, 195, 239),
    rgb(16, 124, 231)
  );
  border-radius: 10px;
`


const Course = ({ course }) => {

  const [link, setLink] = useState({path: `/course/${course.node.slug}`, text: "Go to the course page"})
  const { userData } = UseGlobalStateContext()
  // const userData = true


  // useEffect (() => {
    // const isFree = course.node.plan === PricingPlans.FREE
    // setLink({
    //   path: `/course/${course.node.slug}`,
    //   text: "Go to the course page"
    // })

    // if (userData) {
    //   if (isFree) {
    //     setLink({
    //       path: `/course/${course.node.slug}`,
    //       text: "Go to the course page"
    //     })
    //   } else {
    //      if (userData.isPremium) {
    //       setLink({
    //         path: `/course/${course.node.slug}`,
    //         text: "Go to the course page"
    //       })
    //      } else {
    //       setLink({
    //         path: `/course/${course.node.slug}`,
    //         text: "Get the first " + course.node.freeLessons + " lessons for FREE"
    //       })
    //      }
    //   }
    // } else {
    //   setLink({
    //     path: `/auth`,
    //     text: "Login"
    //   })
    // }
  // },[userData])


  return (
    <CourseCard>

      <div className="sm:grid lg:grid-cols-7 md:grid-cols-12 sm:grid-cols-12">
        <div className="sm:pr-4 lg:col-span-5 md:col-span-8 sm:col-span-8 flex flex-col justify-between">
          <div className="">
            {
              course.node.videoUrl ?
                <VideoContainer>
                  <Video videoUrl={course.node.videoUrl} />
                </VideoContainer> :
                <ImageContainer>
                  <img  src={course.node.image} alt={course.node.title} />
                </ImageContainer>
            }
            <div className="sm:p-4 p-2 ">
            <small>{course.node.createdDate}</small>
              <H4>{course.node.title}</H4>
              <div className="mt-3">
              {course.node.tags.map(tag => (
                <TagText key={tag.title}>{tag.title}</TagText>
              ))}
            </div>
            </div>
          </div>
          <div className={`flex justify-between px-4 py-4 items-center`}>
            <MotionLinkWrapper>
              <PrimaryLink to={`/course/${course.node.slug}`} >{`Go to the course page`}</PrimaryLink>
            </MotionLinkWrapper>
          </div> 
        </div>
        <div className="hidden sm:block pr-3 lg:col-span-2 md:col-span-4 sm:col-span-4 p-4">
          <PreviewGallery previews = {course.node.previews.map( preview => preview.content)}/>
        </div>
      </div>
    </CourseCard>
  )
}

export default Course
