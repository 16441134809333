import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import tw from "tailwind.macro"
import Course from "../components/Course"
import Pagination from "../components/Pagination"
import ListHeader from "../components/ListHeader"
import SEO from "../components/seo"

const Container = styled.div`
  ${tw`pb-8`};
  background-color: ${props => props.theme.colors.background};
`

const Courses = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO url={"/courses"}
           title={"Liquidcoder courses"}
           description={"Free and premium courses on how to build real world mobile and web apps development. If you want to learn how to design, plan, and build a real world mobile or web app, then check out my courses. "}
      />
      <ListHeader title={"Courses"} />

      <Container>
        <div className="container mx-auto grid px-4 lg:pt-8 lg:pb-16 sm:px-0 lg:gap-16 gap-8  grid-cols-1">
          {data.allContentfulCourse.edges.map(course => (
            <Course key={course.node.slug} course={course} />
          ))}
        </div>
        <Pagination
          page={"courses"}
          totalPages={pageContext.numPages}
          currentPage={pageContext.currentPage}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query coursesQuery($skip: Int!, $limit: Int!) {
    allContentfulCourse(
      sort: { fields: createdDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          slug
          title
          createdDate(formatString: "dddd, DD MMMM YYYY")
          excerpt
          freeLessons
          image
          plan
          videoUrl
          tags {
          title
        }
          previews {
            content
          }
        }
      }
    }
  }
`

export default Courses
