import React, { useState } from "react"
import { wrap } from "@popmotion/popcorn"
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"
import tw from "tailwind.macro"

const Container = styled.div`

  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  cursor: pointer;
  
  
  &:hover{
    .next,
    .prev {
        opacity: 1;
      }
    }
  }
  
.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.primary};;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.4s;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  transform: scale(-1);
  
}

img {
  position: absolute;
  max-width: 100%;
}
`

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};


const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

const PreviewGallery = (props) => {

  const previews = props.previews
  const [[page, direction], setPage] = useState([0, 0]);

  const imageIndex = wrap(0, previews.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
      <Container>
        <AnimatePresence initial={false} custom={direction}>
          <motion.img
            key={page}
            src={previews[imageIndex]}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 200 },
              opacity: { duration: 0.2 }
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          />
        </AnimatePresence>
        <div className="next" onClick={() => paginate(1)}>
          {"‣"}
        </div>
        <div className="prev" onClick={() => paginate(-1)}>
          {"‣"}
        </div>
      </Container>
  )
}

export default PreviewGallery