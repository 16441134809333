import  React  from "react";
import { RoundedGradientLink  } from "../elements/shared/buttons";
import { GrayText  } from "../elements/shared/texts";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { motion } from "framer-motion";

const Pagination = ({page, currentPage, totalPages}) => {

    const isFirst = currentPage === 1
    const isLast = currentPage === totalPages
    const nextPage = currentPage + 1
    const previousPage =  currentPage - 1    

    return (
        <div className='flex items-center justify-center py-10'>
            
            <motion.div whileTap={{scale: 0.9}} whileHover={{scale: 1.1}}>
                { !isFirst && <RoundedGradientLink to={ currentPage === 2 ? `/${page}` : `/${page}/${previousPage}`}><BsArrowLeft size={25} /></RoundedGradientLink> }
            </motion.div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <GrayText> Page {currentPage} of {totalPages} </GrayText>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <motion.div whileTap={{scale: 0.9}}  whileHover={{scale: 1.1}}>
            { !isLast && <RoundedGradientLink  to={`/${page}/${nextPage}`}><BsArrowRight size={25}/> </RoundedGradientLink>}
            </motion.div>
        </div>
    )
}

export default Pagination